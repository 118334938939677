.featured {
  padding: 50px 0 50px 0;
  position: relative;
  background-color: rgb(248, 248, 248);

  &__column {
    flex-direction: column;
  }

  &__item {
    img {
      border-radius: 3%;
      display: block;
      width: 70%;
      height: auto;
      box-shadow: 8px 8px 15px rgb(95, 95, 95);
    }
  }

  @include breakpoint-down(small) {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    background-color: white;
    flex-direction: column;
    // z-index: -10;
    &__column {
      width: 100%;
      height: auto;
      padding: 0rem;
    }
    &__item {
      margin: 2rem 0 2rem 0;
      img {
        width: 100%;
        border-radius: 0;
      }
    }
    #vadecum {
      display: none;
    }
  }

  @include breakpoint-up(medium) {
    flex-direction: row;
    &__column {
      width: 40%;
    }
    &__item {
      margin: 20px;
    }
  }
}
