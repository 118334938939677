.footer {
  position: relative;
  height: 20vh;
  width: 100%;
  background-color: white;
  p {
    line-height: 2;
  }
  @include breakpoint-down(small) {
    padding: 5px;
    // transform: translateY(-300%);
    font-size: 1rem;
    line-height: 1;
  }

  &__division {
    margin-left: 5%;
    margin-right: 2%;
    p {
      font-size: 80px;
      font-weight: 100;
    }
    @include breakpoint-down(small) {
      p {
        font-size: 60px;
      }
    }
  }

  &__social {
    flex-direction: row;
  }

  &__social__fb {
    width: 50px;
    height: 50px;
    background-color: $dark-blue;
    border-radius: 50%;
    img {
      filter: invert(100%);
    }
  }

  &__social__insta {
    width: 50px;
    height: 50px;
    background-color: $aqua;
    border-radius: 50%;
    margin-left: 20px;
    img {
      filter: invert(100%);
      width: 28px;
      height: 28px;
    }
  }

  &__logo {
    margin-left: auto;
    img {
      max-height: 18vh;
      width: auto;
    }
    @include breakpoint-down(small) {
      display: none;
    }
  }
}
