//colors
$dark-gray: hsl(0, 0%, 49%);
$gray: hsl(0, 0%, 69%);
$light-gray: hsl(60, 20%, 90%);
$dark-blue: hsl(217, 61%, 25%);
$aqua: hsl(187, 100%, 29%);
$turquoise: hsl(187, 100%, 40%);
$yellow: hsl(51, 100%, 48%);

//font-sizes
$font-smll: 0.875rem; //14px
$font-med: 1.125rem; //18px
$font-large: 1.5rem; //24px
$font-xl: 2rem; //32px
$font-xxl: 3rem; //48px
