html {
  font-size: 100%;
  box-sizing: border-box;
}

*::before,
*,
*::after {
  box-sizing: inherit;
}

body {
  max-height: 100vh;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  background-color: white;
  color: #7d7d7d;
}
body.no-scroll {
  max-width: 100%;
  overflow-x: hidden;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

.container {
  padding: 1.0625rem 3rem;
}

.flex {
  display: flex;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-c {
  justify-content: center;
}
.flex-ai-c {
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(12.5rem, 1fr));
  grid-gap: 6.25rem;
  place-content: center;
  place-items: center;
  padding: 3.125rem 0 0 3.125rem;
}
@media (max-width: 39.9375em) {
  .grid {
    grid-template-columns: 50% 50%;
    grid-gap: 2rem 1rem;
  }
}

.text-button {
  padding: 1rem 1.875rem;
  border-radius: 12px;
  cursor: pointer;
  background-color: #008394;
  color: white;
  border: none;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 400;
}

.is-hidden {
  display: none;
}

@media (max-width: 63.9375em) {
  .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 64em) {
  .hide-for-desktop {
    display: none;
  }
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 300ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 300ms ease-in-out forwards;
}

.header {
  height: 15vh;
}
@media (max-width: 39.9375em) {
  .header {
    height: 15vh;
  }
}
.header.open .header__toggle > span:first-child {
  transform: rotate(45deg);
  background-color: white;
}
.header.open .header__toggle > span:nth-child(2) {
  opacity: 0;
}
.header.open .header__toggle > span:last-child {
  transform: rotate(-45deg);
  background-color: white;
}
.header .overlay {
  z-index: 20;
  opacity: 0;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(#193767, transparent);
}
.header nav {
  height: 15vh;
  position: relative;
  background-color: white;
  padding-left: 5%;
  padding-right: 5%;
}
.header nav img {
  width: 500px;
  height: auto;
}
@media (max-width: 39.9375em) {
  .header nav img {
    width: 250px;
  }
}
.header__toggle {
  z-index: 20;
}
.header__toggle span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #7d7d7d;
  transition: all 300ms ease-in-out;
  transform-origin: 4px 2px;
}
.header__toggle span:not(:last-child) {
  margin-bottom: 5px;
}
.header__menu {
  position: absolute;
  width: calc(100% - 3rem);
  left: 50%;
  transform: translatex(-50%);
  background: white;
  margin-top: 1.5rem;
  padding: 1.625rem;
  border-radius: 5px;
  z-index: 20;
}
.header__menu a {
  display: block;
  color: #7d7d7d;
  padding: 0.625rem;
  text-align: center;
}
.header__links a {
  position: relative;
  font-size: 1.5rem;
  color: #7d7d7d;
  transition: color 300ms ease-in-out;
}
.header__links a:not(:last-child) {
  margin-right: 50px;
}
.header__links a::before {
  content: "";
  display: block;
  position: absolute;
  height: 3px;
  left: 0;
  right: 0;
  bottom: -10px;
  background: #193767;
  opacity: 0;
  transition: color 300ms ease-in-out;
}
.header__links a:hover {
  color: #008394;
}
.header__links a:hover::before {
  background: #193767;
  opacity: 1;
}

.carousel {
  position: relative;
  height: 85vh;
  width: 100vw;
  margin: 0 auto;
}
.carousel__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.carousel__track-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.carousel__track {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  transition: transform 500ms ease-in;
}
.carousel__slide {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.carousel__button {
  z-index: 1;
  filter: invert(50%);
  opacity: 70%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
  cursor: pointer;
}
.carousel__button img {
  position: relative;
  width: 150px;
}
.carousel__button--left {
  left: -10px;
}
.carousel__button--right {
  right: -10px;
}
.carousel__nav {
  transform: translateY(-100%);
}
.carousel__indicator {
  border: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: rgba(65, 65, 65, 0.431);
  margin: 20px;
  cursor: pointer;
}
.carousel__indicator.current-slide {
  background: rgba(65, 65, 65, 0.835);
}

@media (max-width: 39.9375em) {
  .mobile-banner {
    width: 100vw;
  }
  .mobile-banner__image {
    background-image: url(../../img/home/productos-vertical.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100%;
    height: 60vh;
  }
}
.featured {
  padding: 50px 0 50px 0;
  position: relative;
  background-color: #f8f8f8;
}
.featured__column {
  flex-direction: column;
}
.featured__item img {
  border-radius: 3%;
  display: block;
  width: 70%;
  height: auto;
  box-shadow: 8px 8px 15px #5f5f5f;
}
@media (max-width: 39.9375em) {
  .featured {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    background-color: white;
    flex-direction: column;
  }
  .featured__column {
    width: 100%;
    height: auto;
    padding: 0rem;
  }
  .featured__item {
    margin: 2rem 0 2rem 0;
  }
  .featured__item img {
    width: 100%;
    border-radius: 0;
  }
  .featured #vadecum {
    display: none;
  }
}
@media (min-width: 40em) {
  .featured {
    flex-direction: row;
  }
  .featured__column {
    width: 40%;
  }
  .featured__item {
    margin: 20px;
  }
}

.contact {
  position: relative;
  background-color: #193767;
  height: 60vh;
}
.contact img {
  width: 200px;
  height: 200px;
  filter: invert(100%);
  opacity: 90%;
}
@media (max-width: 39.9375em) {
  .contact img {
    width: 150px;
    height: 150px;
  }
}
.contact__column {
  flex-direction: column;
}
.contact__column form {
  width: 100%;
  height: 100%;
}
.contact .text {
  width: 70%;
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
}
@media (max-width: 39.9375em) {
  .contact .text {
    font-size: 1.125rem;
    width: 100%;
  }
}
.contact h2::before {
  position: absolute;
  content: "|";
  color: #008394;
  font-size: 2.5rem;
  font-weight: 900;
}
.contact__item {
  margin: 10px;
}
.contact__item__input {
  width: 90%;
  margin: 40px 0px;
}
.contact__item__input:nth-child(3) {
  margin-bottom: 30px;
}
.contact__item__input input,
.contact__item__input textarea {
  width: 60%;
  border-radius: 6px;
  padding: 16px 16px;
  font-family: inherit;
  font-size: 0.875rem;
}
.contact button {
  margin-left: 25%;
  padding: 2% 15%;
  letter-spacing: 1.5px;
}
@media (max-width: 39.9375em) {
  .contact {
    flex-direction: column;
    height: 100%;
  }
  .contact__column {
    width: 100%;
  }
  .contact__item__input {
    width: 100%;
  }
  .contact__item__input input,
.contact__item__input textarea {
    width: 100%;
  }
  .contact button {
    margin-left: 22%;
  }
}
@media (min-width: 40em) {
  .contact {
    flex-direction: row;
  }
  .contact__column {
    width: 50%;
  }
}

.footer {
  position: relative;
  height: 20vh;
  width: 100%;
  background-color: white;
}
.footer p {
  line-height: 2;
}
@media (max-width: 39.9375em) {
  .footer {
    padding: 5px;
    font-size: 1rem;
    line-height: 1;
  }
}
.footer__division {
  margin-left: 5%;
  margin-right: 2%;
}
.footer__division p {
  font-size: 80px;
  font-weight: 100;
}
@media (max-width: 39.9375em) {
  .footer__division p {
    font-size: 60px;
  }
}
.footer__social {
  flex-direction: row;
}
.footer__social__fb {
  width: 50px;
  height: 50px;
  background-color: #193767;
  border-radius: 50%;
}
.footer__social__fb img {
  filter: invert(100%);
}
.footer__social__insta {
  width: 50px;
  height: 50px;
  background-color: #008394;
  border-radius: 50%;
  margin-left: 20px;
}
.footer__social__insta img {
  filter: invert(100%);
  width: 28px;
  height: 28px;
}
.footer__logo {
  margin-left: auto;
}
.footer__logo img {
  max-height: 18vh;
  width: auto;
}
@media (max-width: 39.9375em) {
  .footer__logo {
    display: none;
  }
}

.catalog {
  background-color: #f8f8f8;
}
@media (max-width: 39.9375em) {
  .catalog {
    flex-direction: column;
    background-color: white;
  }
}
.catalog__filters {
  width: 25%;
  padding: 40px 50px;
  background-color: white;
}
@media (max-width: 39.9375em) {
  .catalog__filters {
    padding: 0;
    margin: 0;
  }
}
.catalog__filters__secondary, .catalog__filters__primary {
  width: 100%;
}
.catalog__filters__secondary h2, .catalog__filters__primary h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 50px;
  color: #7d7d7d;
}
@media (max-width: 39.9375em) {
  .catalog__filters__secondary h2, .catalog__filters__primary h2 {
    margin-bottom: 25px;
    padding-left: 30%;
  }
}
.catalog__filters__secondary a h3, .catalog__filters__primary a h3 {
  font-size: 1.125rem;
  font-weight: 400;
  color: #7d7d7d;
}
@media (max-width: 39.9375em) {
  .catalog__filters__secondary a h3, .catalog__filters__primary a h3 {
    padding-left: 20%;
    width: 100vw;
  }
}
.catalog__filters__secondary a h3:hover, .catalog__filters__primary a h3:hover {
  color: #008394;
}
.catalog__filters__secondary a.btn.active h3, .catalog__filters__primary a.btn.active h3 {
  color: #008394;
}
@media (max-width: 39.9375em) {
  .catalog__filters__secondary a.btn.active h3, .catalog__filters__primary a.btn.active h3 {
    display: inline-block;
    margin: 0;
    padding: 5px 0 5px 5px;
    width: 100vw;
    color: white;
    background-color: #008394;
    padding-left: 25%;
  }
}
.catalog__filters__secondary hr, .catalog__filters__primary hr {
  border-bottom: 0px;
  border-color: white;
}
@media (max-width: 39.9375em) {
  .catalog__filters__secondary hr, .catalog__filters__primary hr {
    display: none;
  }
}
.catalog__filters__primary {
  padding-top: 20px;
}
@media (max-width: 39.9375em) {
  .catalog__filters__primary {
    padding-top: 0;
  }
}
.catalog__grid {
  width: 65%;
  padding-bottom: 3.5rem;
}
.catalog__grid__item {
  flex-direction: column;
  background-color: white;
  box-shadow: 3px 3px 8px #c8c8c8;
  border-radius: 6%;
}
.catalog__grid__item.displayNone {
  display: none;
}
.catalog__grid__item img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 6% 6% 0 0;
}
.catalog__grid__item h2 {
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
@media (max-width: 39.9375em) {
  .catalog__grid {
    width: 100%;
    padding: 5%;
  }
}

.product {
  background-color: #e2e2e2;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 60vh;
}
@media (max-width: 39.9375em) {
  .product {
    flex-direction: column;
    padding: 10%;
    height: 100%;
  }
}
.product__column-l {
  width: 30%;
  height: 100%;
}
.product__column-l__icon {
  height: 100%;
}
.product__column-l__icon img {
  max-height: 100%;
  width: auto;
  border-radius: 20px;
}
@media (max-width: 39.9375em) {
  .product__column-l {
    width: 100%;
    height: auto;
  }
  .product__column-l__icon {
    height: 100%;
  }
  .product__column-l__icon img {
    margin-top: 20%;
    max-width: 100%;
    height: auto;
  }
}
.product__column-r {
  width: 40%;
  padding-top: 0.5rem;
  padding-left: 6.25rem;
}
.product__column-r__data h1 {
  color: #505050;
  max-width: 60%;
  font-size: 2rem;
  font-weight: 600;
  background-color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  text-align: center;
}
.product__column-r__data p {
  padding-left: 1rem;
  color: #505050;
}
.product__column-r__data .action {
  margin-top: 40px;
  background-color: #008394;
  border-radius: 20px;
  color: white;
  width: 15rem;
  text-align: center;
  padding: 5px;
}
@media (max-width: 39.9375em) {
  .product__column-r {
    width: 100%;
    padding: 10%;
  }
  .product__column-r__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .product__column-r__data h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    max-width: 100%;
    padding: 5px 10px;
    margin: 0 0 6px 0;
  }
  .product__column-r__data p {
    margin: 10px 4px;
    text-align: center;
  }
  .product__column-r__data .action {
    max-width: 14rem;
    text-align: center;
    padding: 5px;
    margin: 10px 4px 4px 4px;
  }
}

.description {
  padding: 5% 10% 5% 10%;
  color: #505050;
  font-size: 1.125rem;
}
.description h2 {
  font-weight: 600;
}
.description p {
  font-weight: 300;
}
@media (max-width: 39.9375em) {
  .description {
    padding: 10%;
    font-size: 1.125rem;
  }
}

.about-container {
  width: 100vw;
}

.mission {
  background-image: url(../../img/about/logo-largo.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  width: 100%;
  height: auto;
  margin-top: 2vh;
}
.mission__message {
  padding: 20% 25% 10% 15%;
  font-size: 1.5rem;
  font-weight: 400;
}
@media (max-width: 39.9375em) {
  .mission__message {
    font-size: 1.125rem;
    padding: 20% 15%;
    text-align: left;
  }
  .mission__message__message {
    margin-bottom: 0.5rem;
  }
}
.mission__img {
  width: 40%;
  height: 60vh;
}
.mission__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 39.9375em) {
  .mission__img {
    display: none;
  }
}

.about {
  height: 85vh;
}

.right {
  margin-top: 40px;
}

@media (max-width: 39.9375em) {
  .about {
    height: inherit;
  }

  .right {
    margin-top: inherit;
    padding-bottom: 20px;
  }
}