.about {
  height: 85vh;
}

.right {
  margin-top: 40px;
}
@include breakpoint-down(small) {
  .about {
    height: inherit;
  }

  .right {
    margin-top: inherit;
    padding-bottom: 20px;
  }
}
