.header {
  height: 15vh;
  @include breakpoint-down(small) {
    height: 15vh;
  }
  // Open Hamburger
  &.open {
    .header__toggle {
      > span:first-child {
        transform: rotate(45deg);
        background-color: white;
      }
      > span:nth-child(2) {
        opacity: 0;
      }
      > span:last-child {
        transform: rotate(-45deg);
        background-color: white;
      }
    }
  }

  .overlay {
    z-index: 20;
    opacity: 0;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: linear-gradient($dark-blue, transparent);
  }

  nav {
    height: 15vh;
    position: relative;
    background-color: white;
    padding-left: 5%;
    padding-right: 5%;
    img {
      width: 500px;
      height: auto;
      @include breakpoint-down(small) {
        width: 250px;
      }
    }
  }

  &__toggle {
    //mobile menu toggle
    z-index: 20;
    span {
      display: block;
      width: 30px;
      height: 3px;
      background-color: $dark-gray;
      transition: all 300ms ease-in-out;
      transform-origin: 4px 2px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__menu {
    position: absolute;
    width: calc(100% - 3rem);
    left: 50%;
    transform: translatex(-50%);
    background: white;
    margin-top: 1.5rem;
    padding: 1.625rem;
    border-radius: 5px;
    z-index: 20;

    a {
      display: block;
      color: $dark-gray;
      padding: 0.625rem;
      text-align: center;
    }
  }

  &__links {
    a {
      position: relative;
      font-size: $font-large;
      color: $dark-gray;
      transition: color 300ms ease-in-out;

      &:not(:last-child) {
        margin-right: 50px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -10px;
        background: $dark-blue;
        opacity: 0;
        transition: color 300ms ease-in-out;
      }

      &:hover {
        color: $aqua;

        &::before {
          background: $dark-blue;
          opacity: 1;
        }
      }
    }
  }
}
