.contact {
  position: relative;
  background-color: $dark-blue;
  height: 60vh;

  img {
    width: 200px;
    height: 200px;
    filter: invert(100%);
    opacity: 90%;
    @include breakpoint-down(small) {
      width: 150px;
      height: 150px;
    }
  }

  &__column {
    flex-direction: column;
    form {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    width: 70%;
    color: white;
    font-size: $font-large;
    font-weight: 500;
    @include breakpoint-down(small) {
      font-size: $font-med;
      width: 100%;
    }
  }
  h2::before {
    position: absolute;
    content: "|";
    color: $aqua;
    font-size: 2.5rem;
    font-weight: 900;
  }

  &__item {
    margin: 10px;
  }

  &__item__input {
    width: 90%;
    margin: 40px 0px;
    &:nth-child(3) {
      margin-bottom: 30px;
    }

    input,
    textarea {
      width: 60%;
      border-radius: 6px;
      padding: 16px 16px;
      font-family: inherit;
      font-size: $font-smll;
    }
  }

  button {
    margin-left: 25%;
    padding: 2% 15%;
    letter-spacing: 1.5px;
  }

  @include breakpoint-down(small) {
    flex-direction: column;
    // transform: translateY(-50%);
    height: 100%;
    &__column {
      width: 100%;
    }
    &__item__input {
      width: 100%;
      input,
      textarea {
        width: 100%;
      }
    }
    button {
      margin-left: 22%;
    }
  }

  @include breakpoint-up(medium) {
    flex-direction: row;
    &__column {
      width: 50%;
    }
  }
}
