.about-container {
  width: 100vw;
}
.mission {
  background-image: url(../../img/about/logo-largo.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  width: 100%;
  height: auto;
  margin-top: 2vh;
  &__message {
    padding: 20% 25% 10% 15%;
    font-size: $font-large;
    font-weight: 400;

    @include breakpoint-down(small) {
      font-size: $font-med;
      padding: 20% 15%;
      text-align: left;
      &__message {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__img {
    width: 40%;
    height: 60vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include breakpoint-down(small) {
      display: none;
    }
  }
}
