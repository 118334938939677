.catalog {
  background-color: rgb(248, 248, 248);
  @include breakpoint-down(small) {
    flex-direction: column;
    background-color: white;
  }
  &__filters {
    width: 25%;
    padding: 40px 50px;
    background-color: white;
    @include breakpoint-down(small) {
      padding: 0;
      margin: 0;
    }
  }
  //Secondary Filter Styles
  &__filters__secondary,
  &__filters__primary {
    width: 100%;
    h2 {
      font-size: $font-large;
      font-weight: 500;
      margin-bottom: 50px;
      color: $dark-gray;

      @include breakpoint-down(small) {
        margin-bottom: 25px;
        padding-left: 30%;
      }
    }

    a {
      h3 {
        font-size: $font-med;
        font-weight: 400;
        color: $dark-gray;
        @include breakpoint-down(small) {
          padding-left: 20%;
          width: 100vw;
        }

        &:hover {
          color: $aqua;
        }
      }

      &.btn.active {
        h3 {
          color: $aqua;
          @include breakpoint-down(small) {
            display: inline-block;
            margin: 0;
            padding: 5px 0 5px 5px;
            width: 100vw;
            color: white;
            background-color: $aqua;
            padding-left: 25%;
          }
        }
      }
    }

    hr {
      border-bottom: 0px;
      border-color: white;

      @include breakpoint-down(small) {
        display: none;
      }
    }
  }

  &__filters__primary {
    padding-top: 20px;

    @include breakpoint-down(small) {
      padding-top: 0;
    }
  }

  //Products Grid Styles
  &__grid {
    width: 65%;
    padding-bottom: 3.5rem;

    &__item {
      flex-direction: column;
      background-color: white;
      box-shadow: 3px 3px 8px rgb(200, 200, 200);
      border-radius: 6%;

      &.displayNone {
        display: none;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 6% 6% 0 0;
      }
      h2 {
        font-size: $font-med;
        font-weight: 400;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        text-align: center;
      }
    }

    @include breakpoint-down(small) {
      width: 100%;
      padding: 5%;
    }
  }
}
