.product {
  background-color: rgb(226, 226, 226);
  padding-top: 50px;
  padding-bottom: 50px;
  height: 60vh;

  @include breakpoint-down(small) {
    flex-direction: column;
    padding: 10%;
    height: 100%;
  }

  &__column-l {
    width: 30%;
    height: 100%;
    &__icon {
      height: 100%;
      img {
        max-height: 100%;
        width: auto;
        border-radius: 20px;
      }
    }
    @include breakpoint-down(small) {
      width: 100%;
      height: auto;
      &__icon {
        height: 100%;
        img {
          margin-top: 20%;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  &__column-r {
    width: 40%;
    padding-top: 0.5rem;
    padding-left: 6.25rem;
    &__data {
      h1 {
        color: rgb(80, 80, 80);
        max-width: 60%;
        font-size: $font-xl;
        font-weight: 600;
        background-color: white;
        padding: 5px 10px 5px 10px;
        border-radius: 5px;
        text-align: center;
      }
      p {
        padding-left: 1rem;
        color: rgb(80, 80, 80);
      }
      .action {
        margin-top: 40px;
        background-color: $aqua;
        border-radius: 20px;
        color: white;
        width: 15rem;
        text-align: center;
        padding: 5px;
      }

      // .primary-filters {
      //   margin-top: 20px;
      //   @include breakpoint-down(small) {
      //     margin-top: 5px;
      //   }

      //   img {
      //     margin: 15px;
      //   }
      //   img.big-animals {
      //     width: 4rem;
      //     height: auto;
      //     transform: scaleX(-1);
      //   }
      //   img.small-animals {
      //     width: 3rem;
      //     height: auto;
      //     transform: scaleX(-1);
      //   }
      //   img.antiseptic {
      //     width: 3rem;
      //     height: auto;
      //   }
      // }
    }
    @include breakpoint-down(small) {
      width: 100%;
      padding: 10%;

      &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          font-size: $font-large;
          font-weight: 600;
          text-align: center;
          max-width: 100%;
          padding: 5px 10px;
          margin: 0 0 6px 0;
        }
        p {
          margin: 10px 4px;
          text-align: center;
        }
        .action {
          max-width: 14rem;
          text-align: center;
          padding: 5px;
          margin: 10px 4px 4px 4px;
        }
      }
    }
  }
}

.description {
  padding: 5% 10% 5% 10%;
  color: rgb(80, 80, 80);
  font-size: $font-med;
  h2 {
    font-weight: 600;
  }
  p {
    font-weight: 300;
  }

  @include breakpoint-down(small) {
    padding: 10%;
    font-size: $font-med;
  }
}
