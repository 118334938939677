.carousel {
  position: relative;
  height: 85vh;
  width: 100vw;
  margin: 0 auto;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain; //cover
  }

  &__track-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__track {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    transition: transform 500ms ease-in;
  }

  &__slide {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &__button {
    z-index: 1;
    filter: invert(50%);
    opacity: 70%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: 0;
    cursor: pointer;
    img {
      position: relative;
      width: 150px;
    }
  }

  &__button--left {
    left: -10px;
  }

  &__button--right {
    right: -10px;
  }

  &__nav {
    transform: translateY(-100%);
  }

  &__indicator {
    border: 0;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: rgba(65, 65, 65, 0.431);
    margin: 20px;
    cursor: pointer;

    &.current-slide {
      background: rgba(65, 65, 65, 0.835);
    }
  }
}

// mobile banner
@include breakpoint-down(small) {
  .mobile-banner {
    width: 100vw;
    &__image {
      background-image: url(../../img/home/productos-vertical.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 100%;
      height: 60vh;
    }
  }
}
