html {
  font-size: 100%;
  box-sizing: border-box;
}

*::before,
*,
*::after {
  box-sizing: inherit;
}

body {
  max-height: 100vh;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: "Roboto", sans-serif;
  font-size: $font-med;
  background-color: white;
  color: $dark-gray;

  &.no-scroll {
    max-width: 100%;
    overflow-x: hidden;
  }
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

//spacing
.container {
  padding: 1.0625rem 3rem;
}

//flexbox
.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(12.5rem, 1fr));
  grid-gap: 6.25rem;
  place-content: center;
  place-items: center;
  padding: 3.125rem 0 0 3.125rem;

  @include breakpoint-down(small) {
    grid-template-columns: 50% 50%;
    grid-gap: 2rem 1rem;
  }
}

//buttons
.text-button {
  padding: 1rem 1.875rem;
  border-radius: 12px;
  cursor: pointer;
  background-color: $aqua;
  color: white;
  border: none;
  font-family: inherit;
  font-size: $font-large;
  font-weight: 400;
}

//visibility

.is-hidden {
  display: none;
}

.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}
.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}
